import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTurnOnMenu]'
})
export class TurnOnMenuDirective {
  @Input('appTurnOnMenu') menuId: string;

  constructor() {
    this.menuId = '';
  }

  @HostListener('mouseenter') mouseIn(): void {
    if (!this.updateMenuActive()){
      return;
    }

    this.turnOffActiveMenu();
    this.turnOnActiveMenu();
  }

  private updateMenuActive(): boolean{
    const elmActive: Element | null = this.getElementActive();

    if (elmActive === null){
      return false;
    }

    const hash: string | null = elmActive.getElementsByTagName('a')?.[0].getAttribute('href');

    if (hash === null){
      return false;
    }

    if (hash === null || hash === '#' + this.menuId){
      return false;
    }

    return true;
  }

  private getMenuElement(): HTMLElement | null {
    return document.getElementById('menu');
  }

  private getElementActive(): Element | null{
    const menuElm: HTMLElement | null = this.getMenuElement();

    if (menuElm == null) {
      return null;
    }

    const elmActive: Element | undefined = menuElm.getElementsByClassName('active')?.[0];

    if (elmActive === undefined){
      return null;
    }

    return elmActive;
  }

  private turnOffActiveMenu(): void{
    const elmActive: Element | null = this.getElementActive();

    if (elmActive === null){
      return;
    }

    elmActive.classList.remove('active');
  }

  private turnOnActiveMenu(): void{
    const menuElm: HTMLElement | null = this.getMenuElement();
    if (menuElm == null){
      return;
    }

    const links: HTMLCollection | null = menuElm.getElementsByTagName('a');
    if (links === null || links.length === 0){
      return;
    }

    let linkElm: Element | null = null;
    for (let i = 0; i < links.length; i++) {
      const item: Element = links[i];
      if (item.getAttribute('href') === '#' + this.menuId) {
        linkElm = item;
        break;
      }
    }

    if (linkElm === null){
      return;
    }

    if (linkElm.parentElement?.tagName === 'LI'){
      linkElm.parentElement?.classList.add('active');
    } else {
      linkElm.parentElement?.parentElement?.classList.add('active');
    }
  }
}
