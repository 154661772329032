<div class="row align-items-center">
    <div class="col-lg-6">
        <h3 class="font-weight-semibold line-height-1_4 mb-4"><b>Hola, somos SAVA Seguros</b></h3>
        <p class="text-muted font-size-15 mb-4">
            Una agencia donde actuamos como canal de distribución de seguros, realizamos análisis y 
            tendencias de los riesgos, escuchamos las necesidades y acompañamos a nuestros asegurados 
            en la compra de seguros que garanticen la prestación de servicios, protección del
            patrimonio y de sus familias.
            <br><br>
            
            Nacimos en 1991 con la iniciativa de construir una agencia de seguros competitiva, sostenible, 
            altruista, que apalanca el crecimiento, humano, económico nuestro y de cada uno de los 
            colaboradores, clientes, aliados y aseguradoras que representamos.
        </p>
        <p class="text-muted font-size-15 mb-4">
            <b>En SAVA SEGUROS, encuentras experiencia, responsabilidad, honestidad y cercanía.</b>
        </p>
        <div class="mt-5">
            <app-primary-button-type-link 
                        title="Quiero comunicarme con un asesor"
                        extraClass="mr-2"></app-primary-button-type-link>
        </div>
    </div>
    <div class="col-lg-6">
        <img src="assets/images/logo/logo-dark.png" class="img-fluid" alt="Logo de sava seguros" title="Sava Seguros">
    </div>
    <!--<div class="col-lg-3">
        <div class="mt-4 mt-lg-0">
            <img src="assets/images/team/team-image.jpg" class="img-fluid" alt="Trainer">
            <h3 class="font-weight-semibold line-height-1_4 mb-4"><b>Asesora</b></h3>
            <p class="h5">Adriana Valencia Peña</p>
            <p class="text-muted mb-2">
                <a class="text-primary font-weight-semibold" href="www.whatsapp.com">
                    <i class="icon-xs mr-1" data-feather="message-circle"></i>+57 311 2223344
                </a>
            </p>
        </div>
    </div>

    <div class="col-lg-3">
        <div class="mt-4 mt-lg-0">
            <img src="assets/images/team/team-image01.jpg" class="img-fluid" alt="Trainer">
            <h3 class="font-weight-semibold line-height-1_4 mb-4"><b>Asesora</b></h3>
            <p class="h5">Sandra Valencia Peña</p>
            <p class="text-muted mb-2">
                <a class="text-primary font-weight-semibold" href="www.whatsapp.com">
                    <i class="icon-xs mr-1" data-feather="message-circle"></i>+57 311 2223344
                </a>
            </p>
        </div>
    </div>-->
</div>