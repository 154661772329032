import { Injectable } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SitemapHelper {
  private _isHome: boolean;
  private _hash: string;

  constructor(private _router: Router) {
    const HOME_ROUTE = '/home';
    this._hash = '#vida';
    this._isHome = this._router.url === HOME_ROUTE || this._router.url === '/';

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd)
        this._isHome = event.url === HOME_ROUTE;
    });
  }

  goToSection(e: MouseEvent): void {
    try {
      const link = e.target as HTMLLinkElement;
      const href: string[] = link.href.split('/');
      this.hash = href[href.length - 1];
      const $section = $(this.hash);

      if ($section === null || $section.length === 0) {
        return;
      }

      const offsetTop = $section.offset()?.top;
      if (offsetTop === undefined) {
        return;
      }

      this.goToPositionSection(offsetTop);
    } catch (ex: any) {
      // TODO: Notificar al equipo de soporte
      console.error(ex.message);
    }
    finally {
      e.preventDefault();
    }
  }

  goToMain(): void {
    try {
      const menu: HTMLElement | null = document.getElementById('menu');
      if (menu === null) {
        return;
      }

      this.goToPositionSection(menu.offsetTop);
    } catch (ex: any) {
      // TODO: Notificar al equipo de soporte
      console.error(ex.message);
    }
  }

  goToSectionById(sectionId: string): void {
    const $section = $('#' + sectionId);

    if ($section === null || $section.length === 0) {
      return;
    }
    const offsetTop: number | undefined = $section.offset()?.top;
    if (offsetTop === undefined) {
      return;
    }

    this.goToPositionSection(offsetTop);
  }

  redirectToHomeAndGoToSection(e: MouseEvent): void {
    e.preventDefault();
    this._router.navigate(['/home']);

    setTimeout(() => {
      const link = e.target as HTMLLinkElement;
      const href: string[] = link.href.split('#');

      this.goToSectionById(href[href.length - 1]);
    }, 300);
  }

  private goToPositionSection(position: number): void {
    $('html, body').stop().animate({
      scrollTop: position
    }, 1500);
  }

  public get isHome(): boolean {
    return this._isHome;
  }

  public get hash(): string {
    return this._hash;
  }

  public set hash(value: string) {
    this._hash = value;
  }
}
