<div class="modal fade" id="soat-sura-widget-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-body">
              <div class="text-center">
                  <h3 class="title mb-4">Cotiza tu SOAT</h3>
                  <h4 class="text-uppercase text-primary"><b>SAVA Seguros</b></h4>
              </div>
              <div class="login-form mt-4">
                  <div id="sel-widget"></div>
                  <div class="position-relative text-center mt-4">
                      <span class="login-border"></span>
                      <p class="social-login text-muted font-size-17">Contáctanos</p>
                  </div>
                  <div class="text-center">
                      <ul class="list-inline mt-2 mb-3">
                          <li class="list-inline-item mr-3">
                            <a href="https://www.instagram.com/savaseguros/" class="login-social-icon icon-danger" target="_blank" rel="nofollow">
                              <i-feather class="icon-xs" name="instagram"></i-feather>
                            </a>
                          </li>
                          <li class="list-inline-item mr-3">
                            <a href="https://www.facebook.com/SavaSegurosCo" class="login-social-icon icon-info" target="_blank" rel="nofollow">
                              <i-feather class="icon-xs" name="facebook"></i-feather>
                            </a>
                          </li>
                          <li class="list-inline-item mr-3">
                            <a [href]="whatsappUrlAndMessage" class="login-social-icon icon-success" target="_blank" rel="nofollow">
                              <i-feather class="icon-xs" name="message-circle"></i-feather>
                            </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
