import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-summary',
  templateUrl: './service-summary.component.html'
})
export class ServiceSummaryComponent implements OnInit {
  @Input() serviceName: string;
  @Input() title: string;
  @Input() titleSummary: string;
  @Input() subtitle: string;
  @Input() shortSummary: string;
  @Input() features: string[];
  @Input() imgPath: string;
  @Input() advisoryUrl: string;
  @Input() shopUrl: string;
  @Input() rightPosition: boolean;

  constructor(private _deviceService: DeviceDetectorService) {
    const loremTitleSummary = 'Et harum quidem rerum facilis est et expedita distinctio nam libero tempore cum soluta nobis eligendi cumque.';
    const loremSubtitleSummary = 'Temporibus autem quibusdam et aut officiis debitis aut rerum a necessitatibus saepe eveniet ut et voluptates repudiandae sint molestiae non recusandae itaque.';

    this.serviceName = 'Nombre del servicio';
    this.title = 'Título de la sección';
    this.titleSummary = loremTitleSummary;
    this.subtitle = 'Subtítulo de la sección';
    this.shortSummary = loremSubtitleSummary;
    this.features = [];
    this.imgPath = 'assets/images/features-img.png';
    this.shopUrl = '';
    this.advisoryUrl = '';
    this.rightPosition = false;
  }

  ngOnInit(): void {
  }

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }
}
