<!-- Servicio de Seguro de VIDA -->
<section
    [appTurnOnMenu]="'vida'"
    id="vida"
    class="hero-4-bg pb-2"
    style="background-image: url(assets/images/hero-4-bg.jpg)">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-lg-7">
                <h2 class="hero-4-title mb-4 text-shadow line-height-1_4">Gestiona tu seguro de vida <span class="text-bottom-border">¡¡ YA !!</span> El futuro es incierto y se construye hoy.</h2>
                <p class="text-muted">
                    Trascender en la vida de los seres que amas y apoyar sus proyectos es una demostración de amor.
                </p>
                <p class="text-muted">
                    Ofrecemos seguros de vida que se ajustan a tus necesidades para <b>“Vivir la vida”</b> sin tener
                    que depender de otros, construir autonomía y prepararte para el futuro. Contáctanos para asesorarte
                    sobre nuestros:
                </p>
                <p class="text-muted">
                    <i-feather name="check" class="icon-xs mr-1"></i-feather> <b>Seguros de vida para PERSONAS</b><br>
                    <i-feather name="check" class="icon-xs mr-1"></i-feather> <b>Seguros de vida COLECTIVOS</b><br>
                    <i-feather name="check" class="icon-xs mr-1"></i-feather> <b>Seguros para respaldar una DEUDA</b><br>
                </p>
                <app-primary-button-type-link
                    title="Quiero más asesoría"
                    [url]="SeguroVidaWhatsAppUrlMessage"
                    extraClass="mt-4"></app-primary-button-type-link>
            </div>
            <div class="col-xl-4 offset-xl-2 col-lg-5 col-md-8">
                <div class="hero-4-registration-form mx-auto rounded bg-white mt-5 mt-lg-0">
                    <h5 class="form-title mb-4 font-size-48 text-center">Cotiza YA!</h5>
                    <div class="form-border w-25 mx-auto mb-4"></div>
                    <div class="registration-form">
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="text-muted font-size-15">
                                Selecciona el tipo de seguro*
                            </label>
                            <select name="tipo-seguro" id="tipo-seguro-cotizacion" class="form-control">
                                <option value="para ataques informáticos">Contra Ataques Informáticos</option>
                                <option value="para daños y reparaciones">Daños y Reparaciones en el Hogar</option>
                                <option value="de arrendamiento">De Arrendamiento</option>
                                <option value="de salud">De Salud</option>
                                <option value="de vida" selected>De Vida</option>
                                <option value="para pymes">Para PYMES</option>
                                <option value="para mi mascota">Para tu Mascota</option>
                                <option value="de movilidad">Para tu Vehículo</option>
                                <option value="para mi hogar">Para tu Vivienda</option>
                                <option value="de desempleo">Seguro de Desempleo</option>
                                <option value="SOAT">SOAT</option>
                            </select>
                        </div>
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="text-muted font-size-15">Nombre*</label>
                            <input type="text" class="form-control" id="name-cotizacion" placeholder="Nombre">
                        </div>
                        <div class="form-group mb-4">
                            <label for="exampleFormControlInput1" class="text-muted font-size-15">Correo electrónico*</label>
                            <input type="email" class="form-control" id="email-cotizacion" placeholder="Correo electrónico">
                        </div>
                        <button
                            type="button"
                            class="btn btn-primary text-uppercase btn-block"
                            (click)='cotizar()'>
                            Cotizar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Servicio de Seguro de SALUD -->
<section
    [appTurnOnMenu]="'salud'"
    id="salud"
    class="section pb-3"
    style="background-image: url(assets/images/features-bg-img.png);">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-health.gif"
            serviceName="Seguro de SALUD"
            title="Seguro de SALUD para ti y los que más amas"

            titleSummary="Garantiza la atención: Médica, hospitalaria, urgencias, maternidad.
                Medicina deportiva con planes complementarios. Medicina prepagada y pólizas de
                salud para ti y tu grupo familiar."

            subtitle="Asegura tu SALUD"


            shortSummary="Ofrecemos seguros de salud con diversos niveles de atención, red de médicos generales,
                especialistas, centros de ayudas diagnósticas, clínicas y hospitales. Los cuales se ajustan a
                tus necesidades de atención y presupuesto con el beneficio de realizar diagnósticos y tratamientos
                adecuados, asegurando así tu cuidado y el de los que más quieres."

            [features]='[
                "Bajo costo",
                "Atención domiciliaria y prioritaria",
                "Acceso a especialistas",
                "Medicina alternativa",
                "Hospitalización y cirugía al 100%",
                "Maternidad y cobertura al recién nacido",
                "Urgencias y hospitalización en el exterior"
            ]'
            [advisoryUrl]='SeguroSaludAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroSaludBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>

<!-- Servicio de Seguro de HOGAR -->
<section
    [appTurnOnMenu]="'hogar'"
    id="hogar"
    class="section bg-light feather-bg-img pb-3"
    style="background-image: url(assets/images/features-bg-img-1.png)">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-home.gif"
            [rightPosition]="true"
            serviceName="Seguro para tu HOGAR"
            title="Asegurar tu CASA o APARTAMENTO es proteger tu inversión para toda la vida"

            titleSummary="Un seguro de hogar garantiza la protección de tu futuro y el de tu familia.
                Asegura tu CASA o APARTAMENTO y los contenidos dentro de este."

            subtitle="La vivienda, su estructura y sus contenidos estarán protegidos"
            shortSummary="Cualquier cosa puede pasar. Es mejor tener la tranquilidad de que aquello
                que es tan valioso para ti y tus seres queridos está asegurado... SU CASA O APARTAMENTO."

            [features]='[
                "Protección contra DESASTRES NATURALES",
                "Protección contra INCENDIOS y/o EXPLOSIONES",
                "Protección contra DAÑOS POR AGUA",
                "Protección contra ROBO o PERDIDA",
                "Puedes asegurar el CONTENIDO DE TU VIVIENDA",
                "Daños de plomería, Eléctricos, Vidrios y cerrajería",
                "Asistencia jurídica"
            ]'
            messageWhatsApp='Hola, me gustaría recibir mas asesoría sobre el seguro para mi HOGAR'
            [advisoryUrl]='SeguroHogarAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroHogarBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>

<!-- Pasos para comprar un seguro -->
<section id="pasos" class="section pb-0 pt-0">
    <div class="container-fluid bg-soft-dark">
        <app-steps></app-steps>
    </div>
</section>

<!-- Servicio de Seguro de MASCOTAS -->
<section
    [appTurnOnMenu]="'hogar'"
    id="mascotas"
    class="section pb-3"
    style="background-image: url(assets/images/features-bg-img.png)">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-pet.gif"
            serviceName="Seguro de MASCOTAS"
            title="Asegura a tu MASCOTA"
            titleSummary="Asegurar a tu mascota es la mejor opción para protegerla y cuidarla"
            subtitle="Dale siempre lo mejor a tu mascota"

            shortSummary="Las mascotas se convierten en parte de la familia y las tuyas no
                son la excepción. Por eso es importante que estén protegidas."

            [features]='[
                "Cubre CONSULTA VETERINARIA",
                "Cubre GASTOS VETERINARIOS",
                "Brinda un AUXILIO ECONÓMICO por PERDIDA",
                "GUARDERÍA", "SERVICIO de PELUQUERÍA"
            ]'

            [advisoryUrl]='SeguroMascotaAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroMascotaBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>

<!-- Servicio de Seguro de DAÑOS Y REPARACIONES
<section
    [appTurnOnMenu]="'hogar'"
    id="danios-reparaciones"
    class="section bg-light pb-3 feather-bg-img"
    style="background-image: url(assets/images/features-bg-img-1.png)">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-fix.gif"
            [rightPosition]="true"
            serviceName="Seguro contra Daños y Reparaciones en tu VIVIENDA"
            title="Asegurate contra DAÑOS inesperados en tu VIVIENDA"
            titleSummary="Las reparaciones de una vivienda se pueden convertir en un dolor de cabeza, es mejor contar siempre con un profesional que nos pueda dar una mano"
            subtitle="Tu VIVIENDA siempre en buenas condiciones"
            shortSummary="Tu VIVIENDA debe contar con un seguro contra daños inesperados, tener a la mano un profesional para los daños que se puedan presentar en su vivienda siempre es beneficioso y este seguro te lo ofrece."
            [features]='["Asistencia en DAÑOS de PLOMERÍA", "Asistencia en DAÑOS ELÉCTRICOS", "Asistencia en DAÑOS CERRAJERÍA", "Asistencia en DAÑOS de VIDRIOS", "Asistencia en DAÑOS ESTRUCTURALES", "Asistencia en JARDINERÍA", "Asistencia en CARPINTERÍA", "Asistencia JURÍDICA"]'
            [advisoryUrl]='SeguroReparacionesAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroReparacionesBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>
-->

<!-- Servicio de Seguro de ARRENDAMIENTO -->
<section
    [appTurnOnMenu]="'hogar'"
    id="arrendamiento"
    class="section pb-3"
    style="background-image: url(assets/images/features-bg-img.png);">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-rent.gif"
            serviceName="Seguro de ARRENDAMIENTO"
            title="Seguro de ARRENDAMIENTO DIGITAL"
            titleSummary="Pago garantizado de tu arriendo, sin codeudor y con contratos 100% en línea"
            subtitle="INGRESOS por concepto de ARRENDAMIENTO ASEGURADO"

            shortSummary="Este seguro te permite rentar tu inmueble y despreocuparte por los
                inconvenientes que se puedan presentar en los pagos y daños ocasionados por tu inquilino."

            [features]='[
                "Recibirás el canon de arrendamiento cumplidamente",
                "Pagos de cuotas de administración",
                "Cubre los faltantes al inventario",
                "Estudio financiero sin costo"
            ]'

            [advisoryUrl]='SeguroArrendamientoAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroArrendamientoBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>

<!-- Servicio de Seguro de MOVILIDAD -->
<section
    [appTurnOnMenu]="'movilidad'"
    id="movilidad"
    class="section pb-3 bg-light feather-bg-img"
    style="background-image: url(assets/images/features-bg-img-1.png);">
    <div class="container">
        <app-service-summary
            imgPath="assets/images/features-mobility.gif"
            [rightPosition]="true"
            serviceName="Seguro para tu VEHÍCULO"
            title="Seguro para tu VEHÍCULO"

            titleSummary="Asegura tu vehículo contra todo riesgo por pérdidas totales,
                parciales, daño o robo, responsabilidad civil y daños a terceros.
                ASEGURA TU PATRIMONIO."

            subtitle="Asegura tu MOVILIDAD"
            shortSummary="Dependiendo de tu actividad, destino, hora y sector, eliges cómo
                trasladarte y qué vehículo comprar:  Carro, Moto, Bicicleta, Patineta y otros.
                Asegura tu vehículo contra todo riesgo, ASEGURA TU PATRIMONIO."
            [features]='[
                "Cobertura del 100% en pérdidas totales",
                "Vehículo de reemplazo",
                "Selección de talleres para a reparación",
                "Firma de documentos a domicilio en caso de pérdida total",
                "Inspección a domicilio y virtual",
                "Asistencias: Carro taller, servicio de grúa y abogado en sitio",
                "Revisiones de viaje",
                "Peritajes comerciales con descuento"
            ]'

            [advisoryUrl]='SeguroMovilidadAdvisoryWhatsAppUrlMessage'
            [shopUrl]='SeguroMovilidadBuyWhatsAppUrlMessage'>
        </app-service-summary>
    </div>
</section>

<!-- Servicio de Seguro de SOAT -->
<section
    [appTurnOnMenu]="'soat'"
    id="soat"
    class="section pb-3"
    style="background-image: url(assets/images/features-bg-img.png);">
    <div class="container">
        <app-soat-section
            imgPath="assets/images/features-soat.gif"
            serviceName="SOAT Obligatorio"
            title='Compra tu SOAT en línea'

            titleSummary="El propietario de todo vehículo automotor que circule en el país
                (incluyendo los vehículos extranjeros) está en la obligación de tener SOAT vigente."

            subtitle="Asegúrate contra accidentes"

            shortSummary="Con el SOAT garantizas que, en caso de accidente, las personas
                involucradas tengan derecho a ser atendidas en el hospital, clínica o centro de
                salud más cercano al lugar de los hechos. Al adquirirlo, las víctimas de un
                accidente de tránsito acceden a los servicios de: hospitalización, suministro de
                material médico, medicamentos, procedimientos, tratamientos quirúrgicos, servicios
                de diagnóstico y rehabilitación."

            [features]='[
                "Cubre a conductores, pasajeros, motociclistas, ciclistas y peatones",
                "Cubre los daños corporales causados a las personas en accidentes de tránsito",
                "Tiene incorporado coberturas en caso de muerte como consecuencia de un accidente de tránsito"]'
            [advisoryUrl]='SeguroSoatAdvisoryWhatsAppUrlMessage'
            shopUrl='https://www.suraenlinea.com/v2/sura/soat/seguro-obligatorio?codigoAsesor=1892'>
        </app-soat-section>
    </div>
</section>

<!-- Otros Seguros -->
<section
    [appTurnOnMenu]="'otros-seguros'"
    id="otros-seguros"
    class="section pb-0 bg-light feather-bg-img"
    style="background-image: url(assets/images/features-bg-img-1.png);">
    <div class="container">
        <!-- Titulo -->
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mb-5">
                    <h3 class="title mb-3">Otros Seguros</h3>
                    <p class="text-muted font-size-15">
                        Contamos con una amplia oferta de seguros y nos apoyamos de las mejores compañías de seguros.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- SEGURO DE PROTECCIÓN DIGITAL -->
            <div class="col-lg-4">
                <div class="pricing-box rounded text-center p-4">
                    <div class="pricing-icon-bg my-4">
                        <i class="mdi mdi-access-point-network h1"></i>
                    </div>
                    <h2 class="title mb-3">Seguro de PROTECCIÓN DIGITAL</h2>
                    <h4>Protección contra</h4>
                    <ul class="list-unstyled pricing-item mb-4">
                        <li class="text-muted">Violación de privacidad</li>
                        <li class="text-muted">Virus informáticos</li>
                        <li class="text-muted">Ataques con software malicioso</li>
                        <li class="text-muted">Extorsión cibernética</li>
                        <li class="text-muted">Fraude electrónico</li>
                        <li class="text-muted">Suplantación de identidad</li>
                        <li class="text-muted">Seguro de protección digital</li>
                    </ul>
                    <app-primary-button-type-link
                        title="Quiero más asesoría"
                        [url]="SeguroAtaquesInformaticosAdvisoryWhatsAppUrlMessage"
                        extraClass="pr-btn"
                        [btnOutline]="true"></app-primary-button-type-link>
                    <div class="mt-4">
                        <div class="hero-bottom-img">
                            <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
                        </div>
                    </div>
                </div>
            </div>

            <!-- DESEMPLEO -->
            <div class="col-lg-4">
                <div class="pricing-box rounded text-center p-4">
                    <div class="pricing-icon-bg my-4">
                        <i class="mdi mdi-account-remove h1"></i>
                    </div>
                    <h2 class="title mb-3">Seguro de Desempleo</h2>
                    <h4>Protección contra</h4>
                    <ul class="list-unstyled pricing-item mb-4">
                        <li class="text-muted">Pérdida del Empleo</li>
                        <li class="text-muted">Incapacidad Total Temporal</li>
                    </ul>
                    <app-primary-button-type-link
                        title="Quiero más asesoría"
                        [url]="SeguroDesempleoAdvisoryWhatsAppUrlMessage"
                        extraClass="pr-btn"
                        [btnOutline]="true"></app-primary-button-type-link>
                    <div class="mt-4">
                        <div class="hero-bottom-img">
                            <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
                        </div>
                    </div>
                </div>
            </div>

            <!-- PYMES -->
            <div class="col-lg-4">
                <div class="pricing-box rounded text-center p-4">
                    <div class="pricing-icon-bg my-4">
                        <i class="mdi mdi-factory h1"></i>
                    </div>
                    <h2 class="title mb-3">Seguro para PYMES</h2>
                    <h4>Protección contra</h4>
                    <ul class="list-unstyled pricing-item mb-4">
                        <li class="text-muted">Bienes de la empresa: Edificio, muebles, maquinaria y equipos</li>
                        <li class="text-muted">Responsabilidad civil por afectaciones a terceros</li>
                        <li class="text-muted">Bienes dentro del predio asegurado</li>
                        <li class="text-muted">Daños materiales</li>
                        <li class="text-muted">Lucro cesante</li>
                    </ul>
                    <app-primary-button-type-link
                        title="Quiero más asesoría"
                        [url]="SeguroPymesAdvisoryWhatsAppUrlMessage"
                        extraClass="pr-btn"
                        [btnOutline]="true"></app-primary-button-type-link>
                    <div class="mt-4">
                        <div class="hero-bottom-img">
                            <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
