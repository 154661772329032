<app-header></app-header>

<app-preloader></app-preloader>

<router-outlet></router-outlet>

<!-- Blog -->
<section [appTurnOnMenu]="'blog'" class="section pb-0" id='blog'>
  <div class="container">
    <app-last-news></app-last-news>
  </div>
</section>

<!-- FAQ
<section [appTurnOnMenu]="'faq'" class="section pb-0" style="background-image: url(assets/images/features-bg-img.png);" id="faq">
    <div class="container-fluid bg-soft-dark">
        <app-faq></app-faq>
    </div>
</section>
-->
<!-- About us -->
<section [appTurnOnMenu]="'nosotros'" id="nosotros" class="section bg-light"
  style="background-image: url(assets/images/features-bg-img.png);">
  <div class="container">
    <app-about-us></app-about-us>
  </div>
</section>

<!-- Contact us -->
<section [appTurnOnMenu]="'contactanos'" id="contactanos" class="section pb-0">
  <div class="container pb-3">
    <app-contact-us></app-contact-us>
  </div>
</section>

<!-- Footer -->
<app-footer></app-footer>

<app-soat-sura-widget-modal></app-soat-sura-widget-modal>
