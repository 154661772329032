<div class="row justify-content-center">
  <div class="col-lg-6">
      <div class="text-center mb-5">
          <h3 class="title mb-3">
            <a href="#"
              data-toggle="modal"
              data-target="#soat-sura-widget-modal"
              class="btn btn-primary">COTIZA AQUÍ</a> tu SOAT en línea
          </h3>
          <p class="text-muted font-size-15">{{ titleSummary }}</p>
      </div>
  </div>
</div>

<div class="row align-items-center">
  <div *ngIf="rightPosition === false" class="col-lg-6">
      <div class="mb-4 mb-lg-0">
          <img [src]="imgPath" alt="{{ title }}" title="{{ title }}" class="img-fluid d-block mx-auto">
      </div>
  </div>

  <div class="col-lg-5 offset-lg-1">
      <p class="font-weight-medium text-uppercase mb-2">
          <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{ subtitle }}
      </p>
      <!--<h3 class="font-weight-semibold line-height-1_4 mb-4">
          Características de nuestro <b>{{ serviceName }}</b>
      </h3>-->
      <p class="text-muted font-size-15 mb-4">{{ shortSummary }}</p>
      <p class="text-muted">
          Características y/o beneficios:
      </p>
      <p class="text-muted" *ngFor="let feature of features; let indice=index" [ngClass]="{'mb-2': indice < features.length - 1 }">
          <i-feather name="check" class="icon-xs mr-1"></i-feather> <b>{{ feature }}.</b>
      </p>
      <div class="mt-5">
          <a
              [href]="advisoryUrl"
              class="btn btn-outline-primary mr-md-2"
              [ngClass]="{'btn-block': isMobile()}"
              rel="nofollow"
              target="_blank">
              Quiero más asesoría
          </a>
          <a
              href="#"
              class="btn btn-outline-primary"
              data-toggle="modal"
              data-target="#soat-sura-widget-modal"
              [ngClass]="{'btn-block mb-2': isMobile()}">
              Cotizar
          </a>
      </div>
  </div>

  <div *ngIf="rightPosition" class="col-lg-6">
      <div class="mb-4 mb-lg-0">
          <img [src]="imgPath" alt="" class="img-fluid d-block mx-auto">
      </div>
  </div>
</div>
