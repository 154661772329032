import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToMain(): void {
    const $section = $('#blog-detail');

    if ($section === null){
      return;
    }

    const offsetTop = $section.offset()?.top;

    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 1500);
  }
}
