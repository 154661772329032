import { Component, Input, OnInit } from '@angular/core';
import { WhatsAppHelper } from '../../../../../helpers/whatsapp.helper';

@Component({
  selector: 'app-primary-button-type-link',
  templateUrl: './primary-button-type-link.component.html'
})
export class PrimaryButtonTypeLinkComponent implements OnInit {
  @Input() title: string;
  @Input() toUppercase: boolean;
  @Input() displayBlock: boolean;
  @Input() extraClass: string;
  @Input() rel: string;
  @Input() target: string;
  @Input() url: string;
  @Input() btnOutline: boolean;

  constructor() {
    this.title = '';
    this.extraClass = '';
    this.toUppercase = false;
    this.displayBlock = false;
    this.btnOutline = false;
    this.rel = 'nofollow';
    this.target = '_blanK';
    this.url = WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría para obtener uno de sus seguros');
  }

  ngOnInit(): void {
  }

}
