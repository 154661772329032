import { AboutUsComponent } from './components/shared/about-us/about-us.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ContactUsComponent } from './components/shared/contact-us/contact-us.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DetailComponent } from './pages/blog/detail/detail.component';
import { FaqComponent } from './components/shared/faq/faq.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { LastNewsComponent } from './components/shared/last-news/last-news.component';
import { MapComponent } from './components/shared/map/map.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PreloaderComponent } from './components/shared/preloader/preloader.component';
import { PrimaryButtonComponent } from './components/shared/ui/buttons/primary-button/primary-button.component';
import { ServiceSummaryComponent } from './components/shared/service-summary/service-summary.component';
import { StepsComponent } from './components/shared/steps/steps.component';
import { TurnOnMenuDirective } from './directive/turn-on-menu.directive';
import { FeatherModule } from 'angular-feather';
import { feathersIcons } from './icons/feathers.icons';
import { PageNotFoundComponent } from './components/error/page-not-found/page-not-found.component';
import { PrimaryButtonTypeLinkComponent } from './components/shared/ui/buttons/primary-button-type-link/primary-button-type-link.component';
import { SoatSectionComponent } from './pages/home/soat-section/soat-section.component';
import { SitemapLinksComponent } from './components/shared/sitemap-links/sitemap-links.component';
import { SoatSuraWidgetModalComponent } from './components/modals/soat-sura-widget-modal/soat-sura-widget-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    PreloaderComponent,
    LastNewsComponent,
    FaqComponent,
    ContactUsComponent,
    FooterComponent,
    AboutUsComponent,
    PrimaryButtonComponent,
    MapComponent,
    ServiceSummaryComponent,
    StepsComponent,
    DetailComponent,
    TurnOnMenuDirective,
    PageNotFoundComponent,
    PrimaryButtonTypeLinkComponent,
    SoatSectionComponent,
    SitemapLinksComponent,
    SoatSuraWidgetModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FeatherModule.pick(feathersIcons),
    NgxPaginationModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    SitemapLinksComponent
  ]
})
export class AppModule { }
