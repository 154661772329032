<div class="row justify-content-center">
    <div class="col">
        <div class="text-center mt-5">
            <h3 class="title mb-3">4 pasos para adquirir un seguro con nosotros</h3>
        </div>
    </div>
</div>
<div class="row align-items-center">
    <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
            <div class="wc-box-icon">
                1
            </div>
            <h5 class="font-weight-bold mb-2 wc-title mt-4">Déjanos tus datos para asesorarte</h5>
            <p class="text-muted mb-0 font-size-15 wc-subtitle"></p>
        </div>
    </div>
    <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
            <div class="wc-box-icon">
                2
            </div>
            <h5 class="font-weight-bold mb-2 wc-title mt-4">Evaluamos tu información</h5>
            <p class="text-muted mb-0 font-size-15 wc-subtitle"></p>
        </div>
    </div>
    <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
            <div class="wc-box-icon">
                3
            </div>
            <h5 class="font-weight-bold mb-2 wc-title mt-4">Realizamos el papeleo necesario</h5>
            <p class="text-muted mb-0 font-size-15 wc-subtitle"></p>
        </div>
    </div>
    <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
            <div class="wc-box-icon">
                4
            </div>
            <h5 class="font-weight-bold mb-2 wc-title mt-4">Nos comunicamos contigo para darte la buena nueva</h5>
            <p class="text-muted mb-0 font-size-15 wc-subtitle"></p>
        </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col">
        <div class="text-center mb-5">
            <app-primary-button-type-link 
                title="Quiero más asesoría"
                whatsAppMessage="Hola, estoy en su página web. Quiero mas asesoría para obtener uno de sus seguros"
                extraClass="mt-4"></app-primary-button-type-link>
        </div>
    </div>
</div>