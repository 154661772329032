import { Component } from '@angular/core';
import { ScriptService } from './services/script.service';
import { environment } from 'src/environments/environment';

declare let SuraWidgetSoat: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'savaseguros';

  constructor(private _scriptService: ScriptService) {
    this._scriptService.loadScript('soat-widget-sura', environment.widgets.soat).then(() => {
      this.initSuraWidget();
      document.addEventListener('DOMContentLoaded', this.initSuraWidget, true);
    });
  }

  initSuraWidget() {
    SuraWidgetSoat.mount(
      {
        'codigo-canal': '7771',
        'codigo-asesor': '1892',
        'tenant': 'sura',
        'utm-source': 'www.asesorseguros.com',
        'utm-campaign': 'Widget asesor soat'
      },
      'sura.widget.soat.300.400',
      document.getElementById('sel-widget')
    );
  }
}
