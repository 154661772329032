import { Component, OnInit } from '@angular/core';
import { WhatsAppHelper } from 'src/app/helpers/whatsapp.helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public seguroVidaWhatsAppUrlMessage: string = WhatsAppHelper.getWhatsAppUrl();

  constructor() { }

  ngOnInit(): void { }

  cotizar(): void {
    if (!this.cotizarFormIsValid()) {
      alert('Por favor diligencie todos los campos del formulario de cotización y asegúrese '
             + 'que el correo tenga la estructura adecuada');
      return;
    }

    const tipoSeguro: string | null = this.getTipoSeguroCotizar();
    const nombre: string | null = this.getNombreClienteCotizar();
    const email: string | null = this.getEmailClienteCotizar();
    const message: string = `Me llamo ${nombre}, me pueden enviar por favor una cotización del seguro ${tipoSeguro} ` +
                            `al email ${email}. Se los agradezco.`;

    window.open(`${environment.whatsapp.link} ${message}`);
  }

  public get SeguroVidaWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro de VIDA');
  }

  public get SeguroSaludAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro de SALUD');
  }

  public get SeguroSaludBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro de SALUD');
  }

  public get SeguroHogarAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro de VIVIENDA');
  }

  public get SeguroHogarBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro de VIVIENDA');
  }

  public get SeguroMascotaAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro para mi MASCOTA');
  }

  public get SeguroMascotaBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro para mi MASCOTA');
  }

  public get SeguroReparacionesAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro para DAÑOS y REPARACIONES');
  }

  public get SeguroReparacionesBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro para DAÑOS y REPARACIONES');
  }

  public get SeguroArrendamientoAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro de ARRENDAMIENTO DIGITAL');
  }

  public get SeguroArrendamientoBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro de ARRENDAMIENTO DIGITAL');
  }

  public get SeguroMovilidadAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro para mi VEHÍCULO');
  }

  public get SeguroMovilidadBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar un seguro para mi VEHÍCULO');
  }

  public get SeguroSoatAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría para obtener el SOAT');
  }

  public get SeguroSoatBuyWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero comprar el SOAT para mi VEHÍCULO');
  }

  public get SeguroAtaquesInformaticosAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro contra ATAQUES INFORMÁTICOS');
  }

  public get SeguroDesempleoAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro para DESEMPLEO');
  }

  public get SeguroPymesAdvisoryWhatsAppUrlMessage(): string {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más asesoría sobre el seguro para PYMES');
  }

  private getTipoSeguroCotizar(): string | null {
    const selectId = 'tipo-seguro-cotizacion';
    const select: HTMLSelectElement | null = document.getElementById(selectId) as HTMLSelectElement;

    if (select === null || select === undefined) {
      console.error(`El elemento con id ${selectId} no existe en el DOM`)
      return null;
    }

    return select.options[select.selectedIndex].value;
  }

  private getNombreClienteCotizar(): string | null {
    const inputId = 'name-cotizacion'
    const input: HTMLInputElement | null = document.getElementById(inputId) as HTMLInputElement;

    if (input === null || input === undefined) {
      console.error(`El elemento con id ${inputId} no existe en el DOM`)
      return null;
    }

    return input.value;
  }

  private getEmailClienteCotizar(): string | null {
    const inputId = 'email-cotizacion'
    const input: HTMLInputElement | null = document.getElementById(inputId) as HTMLInputElement;

    if (input === null || input === undefined) {
      console.error(`El elemento con id ${inputId} no existe en el DOM`)
      return null;
    }

    return input.value;
  }

  private cotizarFormIsValid(): boolean {
    const tipoSeguro: string | null = this.getTipoSeguroCotizar();
    const nombre: string | null = this.getNombreClienteCotizar();
    const email: string | null = this.getEmailClienteCotizar();
    const regexp = new RegExp('^([a-zA-Z0-9_.+-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$');

    return tipoSeguro !== null &&
      nombre !== null && nombre.length > 3 &&
      email !== null && regexp.test(email);
  }
}
