import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() toUppercase: boolean;
  @Input() displayBlock: boolean;
  @Input() small: boolean;
  @Input() extraClass: string;
  @Input() clickCallback?: any;

  constructor() {
    this.title = '';
    this.type = 'button';
    this.toUppercase = false;
    this.displayBlock = false;
    this.small = false;
    this.extraClass = '';
  }

  ngOnInit(): void {
  }

}
