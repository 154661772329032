import blogs from '../../assets/data/blogs.json';
import { IBlog } from '../Interfaces/IBlog';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private blogsDB: IBlog[] = blogs;

  constructor() { }

  getAll(): IBlog[] {
    return this.blogsDB;
  }

  getBlogById(id: string): IBlog | undefined {
    return this.blogsDB.find(blog => blog.id === id);
  }

  blogExists(id: string): boolean {
    if(id === undefined || id === null)
      return false;

    return this.blogsDB.find(blog => blog.id === id) !== undefined;
  }
}
