<div class="row justify-content-center">
    <div class="col-lg-6">
        <div class="text-center mb-5">
            <h3 class="title mb-3">Contáctanos</h3>
        </div>
    </div>
</div>
<div class="row align-items-center">
    <div class="col-lg-7">
      <app-map></app-map>
        <!--<div class="custom-form mb-5 mb-lg-0">
            <div id="message"></div>
            <form method="post" action="#" name="contact-form" id="contact-form">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="name">Nombre*</label>
                            <input name="name" id="name" type="text" class="form-control" placeholder="Nombre...">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Correo electrónico*</label>
                            <input name="email" id="email" type="email" class="form-control" placeholder="Correo electrónico...">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="comments">Mensaje*</label>
                            <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Tu mensaje para nosotros..."></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <button type="button" id="submit" name="send" class="btn btn-primary">Enviar mensaje <i class="icon-size-15 ml-2 icon" data-feather="send"></i></button>
                        <div id="simple-msg"></div>
                    </div>
                </div>
            </form>
        </div>-->
    </div>
    <div class="col-lg-5">
        <div class="contact-detail text-muted ml-lg-5">
            <p>
                <i-feather name="mail" class="icon-xs icon mr-1"></i-feather>
                : <span>
                    <a
                        href="mailto:conectemos@savaseguros.com"
                        class="text-primary font-weight-semibold"
                        target="_blank"
                        rel="nofollow">
                        conectemos@savaseguros.com
                    </a>
                </span>
            </p>
            <p>
                <i-feather name="link" class="icon-xs icon mr-1"></i-feather>
                : <span class="text-primary font-weight-semibold">www.savaseguros.com</span>
            </p>
            <p>
                <i-feather name="phone" class="icon-xs icon mr-1"></i-feather>
                : <span>
                    <a
                        href="tel:6044487799"
                        class="text-primary font-weight-semibold"
                        target="_blank"
                        rel="nofollow">
                        (604) 448 7799
                    </a>
                </span>
            </p>
            <p>
                <i-feather name="message-circle" class="icon-xs icon mr-1"></i-feather>
                : <span>
                    <a
                        [href]="whatsappUrlAndMessage"
                        class="text-primary font-weight-semibold"
                        target="_blank"
                        rel="nofollow">
                        (+57) 350 7778763
                    </a>
                </span>
            </p>
            <p>
                <i-feather name="clock" class="icon-xs icon mr-1"></i-feather>
                : <span class="text-primary font-weight-semibold">Lun - Vie | 8:00 AM - 5:00 PM</span>
            </p>
            <p>
                <i-feather name="map" class="icon-xs icon mr-1"></i-feather>
                : <span>
                    <a
                        href="https://g.page/savaseguros?share"
                        class="text-primary font-weight-semibold"
                        target="_blank"
                        rel="nofollow">
                        Crr 81 #32-204 Of. 305, Medellín, Colombia
                    </a>
                </span>
            </p>
        </div>
    </div>
</div>
