<section [appTurnOnMenu]="'blog'" id="blog-detail" class="hero-4-bg pb-0"
    style="background-image: url(assets/images/hero-4-bg.jpg)">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col">
                <h2 class="hero-4-title mb-4 text-shadow line-height-1_4">{{ data?.title }}</h2>
                <div *ngFor="let item of data?.content">
                    <ng-template [ngIf]="!isArray(item)" [ngIfElse]="listContainer">
                        <p class="text-muted" [innerHtml]="item"></p>
                    </ng-template>
                    <ng-template #listContainer>
                        <ul>
                            <li *ngFor="let paragraph of item">
                                <span class="text-muted" [innerHtml]="paragraph"></span>
                            </li>
                        </ul>
                    </ng-template>
                </div>
                <app-primary-button-type-link title="Quiero comunicarme con un asesor" extraClass="mr-2">
                </app-primary-button-type-link>
            </div>
        </div>
    </div>
</section>