import $ from 'jquery';
import { BlogService } from 'src/app/services/blog.service';
import { Component, OnInit } from '@angular/core';
import { IBlog } from 'src/app/Interfaces/IBlog';

@Component({
  selector: 'app-last-news',
  templateUrl: './last-news.component.html',
  styleUrls: ['./last-news.component.scss']
})
export class LastNewsComponent implements OnInit {
  private _blogs !: IBlog[];
  private _currentPage : number;
  private _itemsPerPage : number;

  constructor(private _blogService: BlogService) {
    this._currentPage = 1;
    this._itemsPerPage = 3;
  }

  ngOnInit(): void { }

  goToMain(): void {
    const $section = $('#blog-detail');

    if ($section === null){
      return;
    }

    const offsetTop = $section.offset()?.top;

    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 1500);
  }

  public get blogs() : IBlog[] {
    if(this._blogs === undefined)
      this._blogs = this._blogService.getAll().sort((a, b) => b.creationDate - a.creationDate);

    return this._blogs;
  }

  public get totalBlogs() : number {
    return this._blogs.length;
  }

  public get currentPage() : number {
    return this._currentPage;
  }

  public set currentPage(value : number) {
    this._currentPage = value;
  }


  public get itemsPerPage() : number {
    return this._itemsPerPage;
  }
}
