import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-soat-section',
  templateUrl: './soat-section.component.html',
  styleUrls: ['./soat-section.component.scss']
})
export class SoatSectionComponent implements OnInit {

  @Input() serviceName!: string;
  @Input() title!: string;
  @Input() titleSummary!: string;
  @Input() subtitle!: string;
  @Input() shortSummary!: string;
  @Input() features!: string[];
  @Input() imgPath!: string;
  @Input() advisoryUrl!: string;
  @Input() shopUrl!: string;
  @Input() rightPosition: boolean;

  constructor(private _deviceService: DeviceDetectorService) {
    this.rightPosition = false;
  }

  ngOnInit(): void { }

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }
}
