<nav id="menu" class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
    <div class="container">
        <!-- LOGO -->
        <a (click)="sitemapHelper.goToMain()" class="navbar-brand logo" routerLink="home">
            <img src="assets/images/logo/logo-light.png" alt="Logo Sava Seguros" title="Sava Seguros" class="logo-light" height="48" />
            <img src="assets/images/logo/logo-dark.png" alt="Logo Sava Seguros" title="Sava Seguros" class="logo-dark" height="48" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>

        <!-- MENU -->
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
                <!-- VIDA -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash === '#vida' }">
                  <app-sitemap-links sectionId="vida" title="Vida" cssClass="nav-link"></app-sitemap-links>
                </li>

                <!-- SALUD -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#salud' }">
                  <app-sitemap-links sectionId="salud" title="Salud" cssClass="nav-link"></app-sitemap-links>
                </li>

                <!-- HOGAR -->
                <li class="nav-item dropdown"
                    [ngClass]="{
                        active:
                        sitemapHelper.hash == '#hogar' ||
                        sitemapHelper.hash == '#mascotas' ||
                        sitemapHelper.hash == '#danios-reparaciones'
                    }">
                    <a id="hogar-menu" href="#" (click)="$event.preventDefault()" class="nav-link dropdown-toggle"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Hogar
                    </a>

                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <!-- HOGAR -->
                        <app-sitemap-links sectionId="hogar" title="Hogar" cssClass="dropdown-item"></app-sitemap-links>


                        <!-- MASCOTAS -->
                        <app-sitemap-links sectionId="mascotas" title="Mascotas" cssClass="dropdown-item"></app-sitemap-links>

                        <!-- ARRENDAMIENTO -->
                        <app-sitemap-links sectionId="arrendamiento" title="Arrendamiento" cssClass="dropdown-item"></app-sitemap-links>
                    </div>
                </li>

                <!-- MOVILIDAD -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#movilidad' }">
                  <app-sitemap-links sectionId="movilidad" title="Movilidad" cssClass="nav-link"></app-sitemap-links>
                </li>

                <!-- SOAT -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#soat' }">
                  <app-sitemap-links sectionId="soat" title="SOAT" cssClass="nav-link"></app-sitemap-links>
                </li>

                <!-- OTROS -->
                <li class="nav-item dropdown"
                    [ngClass]="{
                        active: sitemapHelper.hash == '#otros-seguros' || sitemapHelper.hash == '#otros-seguros-fila-2'
                    }">
                    <a id="otros-seguros-menu" href="#otros-seguros" (click)="$event.preventDefault()"
                        class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        Otros
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <!-- ATAQUES INFORMÁTICOS -->
                        <app-sitemap-links sectionId="otros-seguros" title="Ataques informáticos" cssClass="dropdown-item"></app-sitemap-links>

                        <!-- DESEMPLEO -->
                        <app-sitemap-links sectionId="otros-seguros" title="Desempleo" cssClass="dropdown-item"></app-sitemap-links>

                        <!-- PYMES -->
                        <app-sitemap-links sectionId="otros-seguros" title="PYMES" cssClass="dropdown-item"></app-sitemap-links>
                    </div>
                </li>

                <!-- BLOG -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#blog' }">
                  <a href="#blog" class="nav-link" (click)="sitemapHelper.goToSection($event)">
                    Blog
                  </a>
                </li>

                <!-- FAQ
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#faq' }">
                    <app-sitemap-links sectionId="faq" title="FAQ" cssClass="nav-link"></app-sitemap-links>
                </li>
                -->

                <!-- NOSOTROS -->
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#nosotros' }">
                   <a href="#nosotros" class="nav-link" (click)="sitemapHelper.goToSection($event)">
                      Sobre nosotros
                    </a>
                </li>

                <!-- CONTÁCTANOS
                <li class="nav-item" [ngClass]="{ active: sitemapHelper.hash == '#contactanos' }">
                    <a href="#contactanos" class="nav-link" (click)="goToSection($event)">
                        Contáctanos
                    </a>
                </li>
                -->

                <!-- PAGOS -->
                <li class="nav-item">
                    <a href="https://www.segurossura.com.co/paginas/pago-express.aspx#/Pagos"
                        class="nav-link"
                        target="_blank"
                        rel="nofollow">
                        Pagos
                    </a>
                </li>
            </ul>
            <!--
            <ul class="navbar-nav navbar-center">
                <li class="nav-item">
                    <a href="#login" class="nav-link" data-toggle="modal" data-target="#exampleModalCenter">FAQ</a>
                </li>
                <li class="nav-item">
                    <a href="#login" class="nav-link" data-toggle="modal" data-target="#exampleModalCenter-1">Registrarme</a>
                </li>
            </ul>
            -->
        </div>
    </div>
</nav>
