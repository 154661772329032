<div class="row justify-content-center">
    <div class="col-lg-6">
        <div class="text-center mb-5">
            <h3 class="title mb-3">Últimas noticias</h3>
            <p class="text-muted font-size-15">
                Infórmate sobre los temas más nuevos que tenemos para tu bienestar y el de los que más quieres.
            </p>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-4" *ngFor="let blog of blogs | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalBlogs }">
        <div class="blog-box mb-4 mb-lg-0">
            <img src="assets/images/blog/{{ blog.img }}" alt="{{ blog.title }}"
                class="img-fluid d-block mx-auto rounded mb-2">
            <a [routerLink]="['/blog', blog.id]" (click)="goToMain()"
                class="text-dark font-weight-bold h5">
                {{ blog.title }}
            </a>
            <p class="text-muted font-size-15 limit-text">
                {{ blog.summary }}
            </p>
            <a [routerLink]="['/blog', blog.id]" (click)="goToMain()" class="text-primary font-weight-semibold">
                Ver más
                <span class="ml-2 right-icon">&#8594;</span>
            </a>
        </div>
    </div>
</div>

<div class="row justify-content-center">
  <pagination-controls class="pagination"
        [autoHide]="true"
        [responsive]="true"
        (pageChange)="currentPage = $event"
        previousLabel="Anterior"
        nextLabel="Siguiente"></pagination-controls>
</div>
