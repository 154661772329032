import {
  Check,
  Clock,
  Link,
  Mail,
  MessageCircle,
  Phone,
  Map,
  Facebook,
  Instagram
} from 'angular-feather/icons';

export const feathersIcons = {
  Check,
  Clock,
  Link,
  Mail,
  Map,
  MessageCircle,
  Phone,
  Facebook,
  Instagram
};
