import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBlog } from '../../../Interfaces/IBlog';
import { BlogService } from '../../../services/blog.service';
import $ from 'jquery';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  data: IBlog | undefined;

  private _titleParam: string = "";
  private _url: string = "";

  constructor(private _activateRoute: ActivatedRoute,
              private _router : Router,
              private _blogService: BlogService) { }

  ngOnInit(): void {
    this.loadData();
    this.setUrl();
  }

  ngAfterContentChecked() {
    if(this.isUrlChanged()){
      this.loadData();
      this.setUrl();
    }
  }

  private loadData(): void {
    this._activateRoute.params.subscribe(params => {
      this._titleParam = params['title'];
    });
    
    if(!this._titleParam || !this._blogService.blogExists(this._titleParam))
      this._router.navigate(['error/404']);

    this.data = this._blogService.getBlogById(this._titleParam);
    this.slideToTop();
  }

  private slideToTop(): void {
    const $section = $('#blog-detail');
    const offsetTop = $section.offset()?.top;

    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 1500);
  }
  
  private setUrl(): void {
    if(this.isUrlChanged())
      this._url = this._router.url;
  }

  private isUrlChanged(): boolean {
    return this._url !== this._router.url;
  }
  
  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}