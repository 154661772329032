<!-- Footer Start -->
<section class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-5">
          <img src="assets/images/logo/logo-light.png" alt="" class="" height="48">
          <p class="text-white-50 my-4 font-size-15">
            Somos Una agencia donde actuamos como canal de distribución de seguros, realizamos análisis y tendencias de
            los riesgos.
          </p>
          <ul class="list-inline footer-social-icon-content">
            <li class="list-inline-item mr-4">
              <a href="https://www.instagram.com/savaseguros/" class="footer-social-icon" target="_blank" rel="nofollow">
                <i-feather name="instagram"></i-feather>
              </a>
            </li>
            <li class="list-inline-item mr-4">
              <a href="https://www.facebook.com/SavaSegurosCo" class="footer-social-icon" target="_blank" rel="nofollow">
                <i-feather name="facebook"></i-feather>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-4">Seguros</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li>
                <app-sitemap-links sectionId="vida" title="Vida" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="salud" title="Salud" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="hogar" title="Hogar" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="mascotas" title="Mascotas" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="arrendamiento" title="Arrendamiento" cssClass="footer-link"></app-sitemap-links>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-4">Seguros</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li>
                <app-sitemap-links sectionId="movilidad" title="Movilidad" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="soat" title="SOAT" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="otros-seguros" title="Ataques informáticos" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="otros-seguros" title="Desempleo" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="otros-seguros" title="PYMES" cssClass="footer-link"></app-sitemap-links>
              </li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Contacto</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li>
                <app-sitemap-links sectionId="contactanos" title="Contáctanos" cssClass="footer-link">
                </app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="blog" title="Blog" cssClass="footer-link"></app-sitemap-links>
              </li>
              <li>
                <app-sitemap-links sectionId="nosotros" title="Sobre nosotros" cssClass="footer-link"></app-sitemap-links>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">
            {{year}} © Sava Seguros.
            Made with <span style="color: #dc143c">❤</span> by
            <a href="http://www.bitcubico.com" target="_blank" rel="nofollow">BITCUBICO Technologies</a>
            All right reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
