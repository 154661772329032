import { Component, OnInit } from '@angular/core';
import { WhatsAppHelper } from 'src/app/helpers/whatsapp.helper';

@Component({
  selector: 'app-soat-sura-widget-modal',
  templateUrl: './soat-sura-widget-modal.component.html',
  styleUrls: ['./soat-sura-widget-modal.component.scss']
})
export class SoatSuraWidgetModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public get whatsappUrlAndMessage(): string
  {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más información sobre los seguros que ofrece.');
  }
}
