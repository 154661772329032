import { Component, OnInit } from '@angular/core';
import { WhatsAppHelper } from '../../../helpers/whatsapp.helper';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public get whatsappUrlAndMessage(): string
  {
    return WhatsAppHelper.getWhatsAppUrl('Quiero más información sobre los seguros que ofrece.');
  }
}
