import { Component, OnInit } from '@angular/core';
import { SitemapHelper } from 'src/app/helpers/sitemap.helper';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor(private _sitemap: SitemapHelper) { }

  ngOnInit(): void {
  }

  public get sitemapHelper() : SitemapHelper {
    return this._sitemap;
  }
}
