<a
  [href]="url"
  [ngClass]="{
    'text-uppercase': toUppercase, 
    'btn-block': displayBlock, 
    'btn-primary': !btnOutline, 
    'btn-outline-primary': btnOutline
  }"
  class="btn btn-primary  {{ extraClass }}"
  [rel]="rel"
  [target]="target">
  {{ title }}
</a>    
