import { SitemapHelper } from './../../../helpers/sitemap.helper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  sitemapHelper:SitemapHelper;

  constructor(_sitemap: SitemapHelper) {
    this.sitemapHelper = _sitemap;
  }

  ngOnInit(): void {
  }

}
